@import "~@swift-paypal/pp-tokens/build/web/less/tokens.less";
@import "~@swift-paypal/pp-vx/dist/core/utilities/variables.less";

.payroll-costs-form {
  .instructions {
    background-color: @ppui-color-grey-100;
    border-radius: @ppui-border-radius-sm;
    padding: @ppui-spacing-md @ppui-spacing-lg;
  }
}
