.edge-ie-ellipsis-fix() {
  .business {
    width: 100%;
  }
}

// fix for Edge
@supports (-ms-ime-align:auto) {
  .edge-ie-ellipsis-fix()
}
// fix for IE11
@media (-ms-high-contrast:none){
  .edge-ie-ellipsis-fix()
}
