@import (reference) '../../../../src/static/styles.less';

.loan-agreement {
  height: auto;
  margin: 40px 0 20px;

  .step-one-copy {
    padding-bottom: 40px;
    width: 98%;
  }

  .number {
    border-radius: 100px;
    background-color: #003087;
    color: white;
    font-weight: 500;
    font-size: 20px;
    height: 40px;
    margin: 0 auto;
    padding: 5px 6px 8px 15px;
    width: 40px;
  }

  h4 {
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
  }

  p {
    font-size: 0.8125rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .left {
    float: left;
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;
    font-size: 0.8125rem;
  }

  li:before { 
    background-image: url('../../../static/images/icons/check-circle-alt.svg'); 
    background-size: contain; 
    background-repeat: no-repeat;
    content: '';  
    display: inline-block; 
    height: 16px;
    padding-left: 32px;  
    width: 16px; 
  }

  .check_icon {
    fill: #00D78E;
  }

  .print-button {
    max-width: 250px;
    margin: 40px auto;
  }
}