@import (reference) "../../static/styles.less";
@import (reference) "../../styles/variables.less";

.account-banner {
  background-color: @vxWhite;
  padding-top: 1rem !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 0.5rem;
  height: 4.5rem;

  &__row {
    max-width: @maxPageWidth;
    margin: auto;

    .account-name {
      height: fit-content;
      text-align: center;
      font-weight: 500;
      font-family: 'PayPal-Sans', Helvetica Neue, Arial, sans-serif;
      text-align: left;
      padding-left: 2rem;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      overflow: hidden;
    }

    .account-details {
      height: fit-content;
      align-self: center;
    }

    @media @isTabletOrMobile {
      .account-name {
        padding-top: 0.5rem;
      }

      .account-details {
        display: none;
      }
    }
  }
}

.grey-text {
  color: @vxSecondaryGray;
}
