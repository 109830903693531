@import "~@swift-paypal/pp-tokens/build/web/less/tokens.less";
@import "~@swift-paypal/pp-vx/dist/core/utilities/variables.less";

.other-payroll-costs {
  .field__mapped-input {
    margin: @ppui-spacing-md 0 @ppui-spacing-xl-2 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .field-section-header {
    margin: 0;
  }
}
