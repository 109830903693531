@import (reference) "../../../../src/styles/variables.less";
.divider {
  .divider-text, .divider-text-mobile {
    color: @separator;
  }

  .divider-desktop {
    width: 5px;
    height: 100px;
    min-width: 5px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-left: 1px solid @separator;
    margin-left: 7px;
  }

  .divider-text {
    text-align: left;
  }

  .divider-mobile {
    text-align: center;
    margin-right: 2px;
    margin-left: 2px;
    min-height: 5px;
    height: 5px;
    margin-top: 31px;
    border-top: 1px solid @separator;
  }

  .divider-text-mobile {
    text-align: center;
    color: @separator;
  }

  @media @isDesktop {
    .horizontal {
      display: none;
    }
  }

  @media @isTabletOrMobile {
    .vertical {
      display: none;
    }

    .horizontal {
      margin-bottom: 1.5rem;
    }
  }
}

