.estimate-forgiveness-amount {
  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
}
