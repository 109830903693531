@import (reference) "../../../src/static/styles.less";

.contact-us {
  .contact-us-form-group {
    .vx_form-group;
    margin-top: 0;
    padding-top: 0;

    .vx_floatingLabel {
      margin-top: 0;
    }
  }

  &__detail-item {
    padding-bottom: 1rem;
  }

  .text-area {
    height: 127px;

    &.no-error {
      margin-bottom: 1.5rem;
    }
  }

  .continue-button {
    width: 89%;

    @media (max-width: @phonePortraitMax) {
      width: 100%;
      }
  }

  .cancel-button {
    width: 100%;
  }

  .link {
    width: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    width: 50%;

    @media (max-width: @phonePortraitMax) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
  }
}
