@import (reference) "../../../../src/static/styles.less";

.total-balance-tile {
  .vx_panel-well;
  .reversed;
  padding: 24px;
  border-radius: 2px;

  @media (max-width: @maxTablet) {
    padding: 24px 10px 24px 10px;
  }
}

.total-balance-top {
  text-align: center;
}

.total-balance-owed {
  margin-top: -60px;
  text-align: center;
}

.total-balance-buttons {
  text-align: center;
}
