@import (reference) "../../../src/static/styles.less";

.reduction-in-gross-receipts-component {
  #reductionInGrossReceiptsForm {
    @media @isDesktop {
      padding: 0% 20%;
    }
  }

  &__dropdown {
    @media @isDesktop {
      padding: 0% 50% 0% 0%;
    }
  }

  &__btn {
    text-align: center;
    margin-top: @ppui-spacing-lg;
  }
}
