@import (reference) "../../../../src/styles/global-styles.less";

.upload-documents-component {
  max-width: 21.875rem;
  margin: 1.875rem auto;

  &.increased-width {
    max-width: none;
  }

  .left-align {
    text-align: left !important;
  }

  .upload-section {
    color: @vxGray;

    .dropzone-area {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 130px;
      min-height: 30px;
      width: 100%;
      border: 2px @vxGray;
      border-style: dashed;
      border-radius: 4px;
      background-color: @vxWhite;
      outline: none;

      &:hover, &:active, &:focus {
        padding-bottom: .5rem;
        border: .125rem solid @tooltipIcon;
        border-style: dashed;
        border-radius: .25rem;
        outline: @tooltipBorder solid .25rem;
      }
    }
  }

  .file-queue-section {
    color: @vxDarkGray;

    ul {
      background-color: @vxNotificationBlue;
      margin: 1.5rem 0;
      padding: 0 1rem;
      list-style-type: none;

      li {
        &.item {
          align-items: center;
          display: flex;
          height: 3.5rem;
        }

        .item {
          &__name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &__size {
            margin-left: 0.5rem;
          }

          &__remove-btn {
            margin-left: 0.3rem;
            max-height: 1.5rem;
            padding: 0;

            &:hover, &:active, &:focus {
              padding-bottom: 1.5rem;
              border: .125rem solid @tooltipIcon;
              border-radius: 12.5%;
              outline: @tooltipBorder solid .25rem;
            }

            .remove-btn__icon {
              font-size: 1.5rem;
            }
          }
        }

        &.not-last-item {
          border-bottom: 1px solid @vxLightGray;
        }

        a {
          cursor: pointer;
          position: absolute;
          top: 9px;
          right: 0px;
          padding: 5px 10px 5px 10px;
        }
      }
    }
  }

  .submit-section {
    text-align: center;

    &__alert {
      margin-top: 0.625rem;
      margin-bottom: 2rem;
    }

    .minimum-width {
      margin: 0.625rem;
      min-width: 10rem;
    }
  }

  .upload-warning {
    margin-top: 0.625rem;
    margin-bottom: 2rem;
  }
}

.uploadDocuments__checkbox > label{
  font-size: 0.9375rem;
}
