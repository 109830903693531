.heading {
    text-align: center;
    margin-bottom: 3.5rem;
    margin-top: 1rem;
}

.centered-container {
    text-align: center;
}


.confirmation-heading {
    margin-bottom: 2rem;
}

.payment-auth-link {
    padding: 0 !important;
}

.single-select-label > div {
        margin-right: 0;
}

.other-amount-input {
    * {
        text-align: center;
    }
}

.confirmation-logo {
    margin: 2rem 0;
    width: 10rem;
    height: 10rem;
}

.bank-container {
    display: flex;
    gap: 1.25rem;
}

.input-container {
    margin-top: 1rem;
    margin-bottom: 2.25rem;
}

.routing-number {
    padding-bottom: 0.25rem;
}

.w100 {
    width: 100%;
}

.change-link {
    flex: 1;
    margin: auto 0;
    text-align: end;
}

.payment-disclaimer {
    margin-bottom: 2rem;
    text-align: center;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.select-payment-btn {
    margin-top: 2.25rem;
}

.radio-label {
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.non-verified-label {
    margin-left: 2.375rem;
    padding: 0.75rem;
}