@import (reference) "../../../src/static/styles.less";

.calculate-payroll-date-component {
  #calculatePayrollDataForm {
    @media @isDesktop {
      padding: 0% 20%;
    }
  }

  &__q2 {
    @media @isDesktop {
      padding: 0% 50% 0% 0%;
    }
  }

  &__btn {
    margin-top: @ppui-spacing-xl;
    text-align: center;
  }
}
