.override-autofill-styles() {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.center-align() {
  display: flex;
  align-items: center;
}

.ellipsify() {
  text-overflow: ellipsis;
  overflow: hidden;
}
