@import (reference) "../../../../src/static/styles.less";

.case-worker-container {
  padding: 0;

  @media (max-width: @phonePortraitMax) {
    padding: 0 0 5rem;
  }

  .have-questions-title {
    color: @ppui-color-grey-600;
    margin-block-end: 0.5rem;
    font-weight: 400;
  }

  .call-us-text, .call-us-hours {
    margin: 0;
  }

  .case-worker-content {
    @media screen and (max-width: @phonePortraitMax) {
      padding-top: 9px;
    }
    .case-worker-name {
      font-size: 1.125rem;
      color: #2c2e2f;
      font-weight: bold;
    }
  }
}
