@import '~@swift-paypal/pp-vx/dist/core/utilities/variables.less';

.amount-option-text {
  background-color: @ppui-color-grey-100;
  display: flex;
  flex-direction: column;
  height: 5rem;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 1rem;

  .row {
    display: flex;
    flex-direction: row;
    padding: 0.1rem 0;

    .text {
      flex-grow: 0;
    }

    .value {
      min-width: 9rem;
      text-align: right;
    }

    .dot-leader {
      background-position: bottom;
      height: 1rem;
      flex-grow: 1;
      margin: 0 0.375rem;
      padding: 0.1rem 0 0 0;
    }

    &.caption {
      color: @ppui-color-grey-600;
    }
  }
}
