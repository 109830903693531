@import (reference) "../../../src/static/styles.less";

.vx_globalNav-list {

.menuNameLabel {
  padding: 0px;
  color: #2c2e2f;
  text-align: left;
}

.menuEmailLabel {
  padding: 0px;
  color: #2c2e2f;
  font-weight: 300;
}

button {
  padding: 2em 0 0.65em;
  font-weight: 400;
  font-size: 0.8125rem;
  color: #ffffff;
}

  .bold-nav-links {
    font-weight: 500;
  }
}

.default-cursor {
  cursor: default;
}

@media (max-width: 768px) {
  .portal_header {
    .vx_globalNav-links {
      text-align: left;
      margin-left: 34px;
    }
  }
}