@import (reference) "../../../../src/static/styles.less";

.transactionList {
  margin: auto;
  max-width: @maxPageWidth;
  .hide {
    max-height: 0;
  }
}

.transactionAmount {
  /*! @noflip */
  direction: ltr;
}

.highlightTransactionRow,
.highlightTransactionPanel,
.highlightInstallmentRow,
.highlightInstallmentPanel {
  border-left: 4px solid @vxPalBlue;
  margin-left: -4px; // offset the padding caused by the border
}

.highlightTransactionPanel,
.highlightInstallmentPanel {
  background-color: @vxWhite;
  border-left-color: @vxLightPalBlue;
  overflow: hidden;
  max-height: 13em;
  transition: max-height 0.5s ease;
  dd {
    margin-left: 0;
    border-bottom: 1px solid @vxLightGray;
    line-height: 2em;
    color: @vxSecondaryGray;
    div:nth-child(2) {
      text-align: right;
    }
  }
}

.transactionRow,
.installmentRow {
  border-top: 1px solid @tertiaryLightGray;
  display: block;
  position: relative; // for support of hasSpinner class (when inline txn is loading)
  border-left: 4px solid transparent;
  margin-left: -4px;
  &:last-child {
    border-bottom: none;
  }

  .transactionDescriptionContainer {
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    width: 100%;

    &:focus {
      text-decoration: underline;
    }
    &:active {
      text-decoration: none;
    }
  }

  .transactionDescription,
  .installmentSummary .creditPlanDescription {
    padding-top: 0.9em;
    line-height: 1.5em;
  }

  .transactionAmount,
  .installmentSummary .balance {
    line-height: 1rem;
    margin-top: 0.5em; // currency code shown
    height: 50%;

    &.currencyConversion {
      padding-top: 0.4em;

      .currencyCode {
        padding-top: 0.7em;
      }
    }

    .currencyCode {
      font-family: @fontFamily;
    }
  }

  .actions {
    float: right;
  }

  .transactionType,
  .installmentSummary .creditPlanType {
    display: block;
    padding-top: 0.02em;
    font-weight: 500;
  }

  .creditPlan-balance {
    color: @vxDarkGray;
  }

  .transactionType {
    @media (min-width: 640px) {
      width: 96%;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .transactionType-principal {
    display: block;
    color: @vxDarkGray;
  }

  &.year-title {
    /* adjust the min-height to 2/3 the height of the transactionRow and vertically center the content */
    min-height: 2.66em;
    padding-top: 0;
    padding-bottom: 0;

    line-height: 2.66em;

    .span1 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.transactionItem {
  padding: 1.65em 0 0.55em;
  min-height: 7em;
  .linkedBlock {
    margin: 0;
  }
}

.dateParts {
  display: block;
}

.dateDay,
.dateMonth,
.dateYear {
  display: block;
  text-align: center;
  line-height: 1.1em;
}

.dateMonth {
  text-transform: uppercase;
}

.transactionDetailsContainer {
  padding-left: 0;
  position: relative;
}

/* Inline actions */
.transactionDetailsContainer,
.detailedTable-transactionItem_actions {
  .action,
  .action-highlight {
    margin-bottom: 0.5em;
    margin-left: -0.25em;

    .icon {
      color: @vxGray;
    }

    a {
      display: inline-block;
      margin: 0;
      padding: 0 0.82em 0 0.4em;
      border-right: 1px solid @vxDarkGray;
      white-space: nowrap;

      &:first-of-type {
        padding-left: 0.2em;
      }

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .action-highlight .icon {
    color: @vxOrange;
  }
}

.transactionDescription {
  font-weight: 400;
}

.transactionDescription,
.transactionAmount,
.installmentSummary .creditPlanDescription,
.installmentSummary .balance {
  color: @vxDarkGray;
}

.transactionDescription,
.installmentSummary .creditPlanDescription {
  display: block;

  overflow: hidden;
  white-space: nowrap;

  text-overflow: ellipsis;
}

.mobileTransactionDetails {
  padding-right: 1.2em;
}

.transactionAmount,
.installmentSummary .balance {
  padding-right: 1.2em;
  text-align: right;

  .netAmount {
    color: @vxDarkGray;
  }

  .balanceAmount {
    display: block;
    color: @vxDarkGray;
  }
}

.detailsLink {
  a {
    font-weight: 400;
  }
}

a.export-link {
  margin-top: 2em;
  font-weight: 400;
  text-align: right;
}

.dateDay {
  font-size: 1.8em;
  line-height: 0.7em;
  text-align:center;
}

.dateYear {
  line-height: 1.3em;
}

.download-link {
  div[role="button"] {
    .pull-right;
  }
}

