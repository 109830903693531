@import (reference) "../../../../src/static/styles.less";
.stipulation-items {
  .detail-text {
    margin: 1rem 0 1rem 0; 
  }

  .stipulation {
    margin: 0 8px 0 8px;
    .center-align();
    height: 60px;

    .call-to-action {
      text-align: right;
    }
  }

  .fulfilled-container {
    float: right;
    width: 136px;
    margin: 0;
  }
  .fulfilled {
    color: @vxLightGreen;
    font-weight: bold;
  }

  .list-items {
    padding: 0.25em 0 0.25em 0;
  }

  .icon-align {
    .center-align();
  }

  .upload-icon {
    background: url('../../../static/images/icons/upload.svg') no-repeat;
    width: 40px;
    height: 25px;
  }

  .fixed-btn {
    margin: 0;
    width: 136px;

    @media (max-width: @phonePortraitMax) {
      width: 100%;
      padding: 0.5rem 0.25rem;
    }
  }
}
