@import (reference) "../../../../src/static/styles.less";

.forgiveness-signoff-completed {
  .forgiveness-amount {
    background-color: @ppui-color-grey-100;
    display: flex;
    padding: @ppui-spacing-xl;
    justify-content: space-between;

    &__label {
      flex-wrap: wrap;
      font-size: @ppui-size-text-100;
    }

    &__value {
      font-size: @ppui-size-text-100;
      padding: 0 @ppui-spacing-xl 0 @ppui-spacing-xl-2;
    }
  }
}