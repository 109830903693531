@import (reference) "../../../../src/static/styles.less";

.password-field {
  .show-hide-button {
    z-index: 2;
    position: absolute;
    top: 16px;
    right: 17px;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    textAlign: center;
    textDecoration: none;
    WebkitUserSelect: none;
    MozUserSelect: none;
    userSelect: none;
  }

  .error-list {
    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .password-checklist {
    margin-left: 16px;
    line-height: 23px;

    .icon-col {
      padding: 0;
      flex: none;

      &.oval {
        background-color: @ppui-color-grey-400;
        border-radius: 2px;
        height: 4px;
        margin: 7.5px;
        max-width: 4px;
        width: 4px;
      }

      &.check {
        height: 5px;
        max-width: 5px;
        width: 5px;
      }
    }

    .checklist-text {
      padding: 0;
    }

    .meets-reqs {
      color: @ppui-color-green-500;
    }

    .fails-reqs {
      color: @ppui-color-grey-600;
    }
  }
}

