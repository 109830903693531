@import (reference) "../../../src/styles/variables.less";

.maintenance {
  padding: @ppui-spacing-xl-3 12rem 6rem 12rem;
  @media @isTabletOrMobile {
    padding-left: @ppui-spacing-xl-5;
    padding-right: @ppui-spacing-xl-5;
  }
  @media @isSmallMobile {
    padding-left: @ppui-spacing-xl-3;
    padding-right: @ppui-spacing-xl-3;
  }
}

.gear-icon > img {
  width: 100px;
  height: auto;
}
