@import (reference) "../../../../src/styles/global-styles.less";

.loan-state
{
  text-align: center;
  margin: 20px;
}

.void-body
{
  margin: 0 3rem;
}

.wait-icon
{
  padding-top: 4rem;
}
