.payroll-verification {
  &__content-item {
    padding: 0.75rem 0;
  }

  &__content-list-header {
    padding: 0.75rem 0 0;
  }

  &__content-list-item {
    padding: 0;
  }
}