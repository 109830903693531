@import (reference) "../../../../styles/variables.less";

.stipulation {
  &__name {
    @media @isSmallMobile {
      padding-bottom: @ppui-spacing-sm;
    }
  }

  &__name, &__name--fulfilled {
    @media @isSmallMobile {
      padding-bottom: @ppui-spacing-sm;
      text-align: center;
    }
  }

  &__row {
    align-items: center;
    min-height: @ppui-spacing-xl-4;

    &--fulfilled {
      align-items: center;
      background-color: @summaryBackground;
      border-radius: @ppui-border-radius-sm;
      height: 100%;
      padding: @ppui-spacing-sm 0;
    }

    @media @isSmallMobile {
      min-height: @ppui-spacing-xl-5;
    }
  }

  &__button-column, &__button-column--fulfilled {
    text-align: right;

    @media @isSmallMobile {
      text-align: center;
    }
  }

  &__button {
    margin: 0;
    min-width: 7.5rem;

    &:active, &:focus, &:hover {
      color: @ppui-color-white;
    }

    &--fulfilled {
      color: @ppui-color-green-500;
      line-height: @ppui-line-height-xl-2;
      padding-right: @ppui-spacing-xl;

      @media @isSmallMobile {
        line-height: inherit;
        padding-right: 0;
      }
    }

    @media @isSmallMobile {
      width: 8.5rem;
    }
  }

  &__divider {
    border-color: @ppui-color-grey-200;
    margin: @ppui-spacing-xl 0;

    &:last-child {
      display: none;
    }
  }
}
