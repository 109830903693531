@import (reference) "../../src/static/styles.less";
@import (reference) "./mixins.less";

button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: none;
}

.portal-container {
  background: @vxWhite;
}

.stipulation-content-container {
  margin: 1rem;
}
/* begin: layout styles */
.container {
  padding-right: 10px;
}

.mobile-reverse-order {
  @media (max-width: @maxPhone) {
   display: flex;
   flex-direction: column;
  }
}

.mobile-reverse-top {
  @media (max-width: @maxPhone) {
   order: 1;
  }
}

.mobile-reverse-bottom {
  @media (max-width: @maxPhone) {
   order: 2;
  }
}

body {
  font-family: @ppui-font-family-400, "Helvetica Neue", "Arial", "sans-serif";
  height: auto;
  overflow-x: hidden;
}

.align-left {
  text-align: left;
}

.align-center, .center {
  text-align: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

.center-content {
  margin: 0 auto;
  text-align: center;
}

.fixed-layout-table {
  table-layout: fixed;
}

.page-title {
  font-size: 1.875rem;

  &.bold {
    font-weight: 400;
  }
}

.account-name {
  font-size: 1rem;
  padding: 0rem;
}

.account-hr {
  margin-top: 0.375rem;
  margin-bottom: 0.75rem;
}

.summary {
  background-color: @summaryBackground;

  @media @isDesktop {
    min-height: calc(100% - @footerMinHeight);
  }

  &-container {
    margin-bottom: 1rem;
    padding: 1rem;
  }

  &-tile {
    margin-bottom: 1.5rem !important;

    .ppvx_text--heading-md {
      margin-bottom: @ppui-spacing-xl;
    }
  }
}

.standard-layout {

  @media @isDesktop {
    &--mobile {
      display: none;
    }
  }

  @media @isTabletOrMobile {
    &--desktop {
      display: none;
    }

    &__sidebar {
      position: inherit;
    }
  }

  &__sidebar {
    background-color: @lightGrayBackground;
    height: inherit;

    .left-gutter {
      padding-top: 2.1rem;
      min-height: calc(100vh - @headerHeight - @footerHeightMobile);
    }

    &--desktop:empty {
      display: none;
    }
  }

  &__content {
    margin-bottom: 1rem;
  }

  &__sidebar--desktop:empty + &__content {
    flex: 0 0 90%;
    max-width: 100%;
  }
}

.privacy-policy-page, .terms-of-use-page {
  h1 {
    display: block;
    font-family: "PayPalSansBig-Light",Helvetica Neue,Arial,sans-serif;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    text-transform: none;
  }

  h2 {
    display: block;
    font-family: "PayPalSansBig-Light",Helvetica Neue,Arial,sans-serif;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    text-transform: none;
  }

  h3 {
    display: block;
    font-family: "PayPalSansBig-Regular",Helvetica Neue,Arial,sans-serif;
    font-size: 0.83rem;
    font-weight: bold;
    line-height: 1.15rem;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
}

.content {
  padding-top: 25px;
}

.have-questions-title {
  font-size: 1.25rem;

  .contact-icon {
    padding-right: 0.375rem;
    height: 0.875rem;
    width: auto;
  }

  .contact-us {
    font-size: 0.9375rem;
  }
}

/* end: layout styles */

/*  begin: custom hide/show classes */

.vx_visible-phone-block,
.vx_visible-tablet-block {
  .responsive-invisibility();
}

@media @isTabletOrMobile {
  .vx_visible-phone-block {
    .responsive-visibility();
  }
}

@media (min-width: @maxPhone + 1) and (max-width: @maxTablet) {
  .vx_visible-tablet-block {
    .responsive-visibility();
  }

  .vx_hidden-tablet {
    .responsive-invisibility();
  }
}

@media @isDesktop {
  .vx_hidden-desktop {
    .responsive-invisibility();
  }

  .vx_visible-desktop-block {
    .responsive-visibility();
  }
}
/* end custom hide/show classes */

/* begin: display styles */

.inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

@media screen and (max-width: @maxPhone) {
  .pull-right-sm {
    float: right;
  }
}

@media screen and (max-width: @maxTablet) {
  .pull-right-md {
    float: right;
  }
}

.pull-right {
  float: right;
}

.full-width {
  width: 100%;
}

/* end: display styles */

/* begin: padding, border, and margin styles */

.no-padding {
  padding: 0;
}

.padding-bottom-sm {
  padding-bottom: 10px;
}

.padding-bottom-md {
  padding-bottom: 2rem;
}

.padding-top-bottom-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-top-bottom-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.no-margin {
  margin: 0;
}

.margin-bottom-xs {
  margin-bottom: 0.625rem !important;
}

.margin-bottom-sm {
  margin-bottom: 1.2rem;
}

.margin-bottom-md {
  margin-bottom: 2.5rem;
}

.margin-top-xs {
  margin-top: 0.625rem;
}

.margin-top-sm {
  margin-top: 1.2rem;
}

.margin-top-lg {
  margin-top: 3.6rem;
}

.margin-left-sm {
  margin-left: 1.2rem;
}

.left-gutter {
  padding-left: 2.25rem;
  height: inherit;
}

.hr-padding {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
  padding-bottom: 1.25rem;
}
/* end: padding, border, and margin styles */
.clickable {
  cursor: pointer;
  font-weight: 400;
}

/* begin: text styles */

.bold-body-text {
  font-weight: 500;
}

.error-color {
  color: @vxRed;
}
.word-break {
  word-break: break-word;
}

.vertical-align-middle {
  vertical-align: middle;
}
/* end: text styles */

.inconspicuous-link {
  font-size: 80%;
  line-height: 1rem;
}

.button-link {
  outline: none;
  background: none!important;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.dropzone-container {
  cursor: pointer;
}

.reversed {
  background-color: @vxAccessibleBlue;
  color: @vxWhite;

  .vx_panel-text {
    color: @vxWhite;
  }
}

.portal-container {

  .portal-content {
    padding-bottom: 0;
    min-height: calc(100vh - @footerMinHeight);
    position: relative;
    max-width: @maxPageWidth;

    @media @isDesktop {
      .banners {
        margin-left: @marginCompensation;
        margin-right: @marginCompensation;
      }
    }

    @media screen and (max-height: 700px) {
      min-height: calc(100vh);
    }
  }
}

.security-container {
  min-height: 61vh;

  @media @isTablet {
    padding-left: 0;
    padding-right: 0;
    .standard-layout__sidebar {
      padding-left: 1rem;
    }
  }

  @media @isMobile {
    min-height: none;
  }
}

.vx_panel-well {
  &.bordered {
    border: 1px solid @vxSecondaryLightGray;
    border-radius: 2px;
  }
}

.vx_alert-warning {
  background-color: #FDFDF1;
  @media (max-width: @maxPhone) {
    border-left-width: thin;
    border-right-width: thin;
  }
  &::before {
    color: #ff9600;
    inline-size: fit-content;
    font-size: x-large;
  }
}

.alertbox {
  padding: 1rem;
  line-height: 1.5rem;
  color: black;
  padding: 0;
}

.fixed-btn {
  margin: 40px 0 20px 0;
  width: 203px;
  font-weight: normal;
}

/* begin: page transition styles */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
/* end: page transition styles */

/* begin: loading spinner styles */
div{
  &.spinner {
    &.display-none {
      display: none;
    }
    display: flex;
    padding: 0;
    margin-top: 0;
    align-items: center;
    height: 100vh;

    @media @isMobile {
      height: calc(100vh - @headerHeight);
    }
  }
}
/* end: loading spinner styles */

/* begin: form styles */
form {
  //override webkit browser autofill styles for better compatibility with floating labels
  .override-autofill-styles();
}

.custom_has-error {
  color: @vxRed;
  border-color: @vxRed;
}

.unround-corners {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* end: form styles */
