.sba-3508s-form {
  &__button {
    min-width: 10rem;
  }

  .detail-tooltip {
    button {
      font-size: inherit;
      height: 1.25rem;
      padding: 0 0.3rem;
      width: 2rem;
    }
  }
}
