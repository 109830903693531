@import (reference) "../../../../../static/styles.less";

.total-forgiveness-amount-table {
  background-color: @ppui-color-grey-100;
  margin: 3rem 0 2rem 0;
  padding: 0.5rem 1rem 3rem 1rem;

  .desktop-view {
    display: block;

    @media @isMobile {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media @isMobile {
      display: block;
    }

    #TableHeaderTabsGroup-nav {
      margin-bottom: 0;
    }

    #tab1, #tab2 {
      font-size: 0.7rem;
      padding: 0 0.5rem;
      width: 50%;
    }

    #panel1, #panel2 {
      margin: 0;
    }
  }


  .row {

    .label {
      padding: 0.875rem 0;
      text-align: left;
      font-size: 0.85rem;
    }

    .amount {
      text-align: right;
      font-size: 0.875rem;
    }

    .col-name {
      text-align: right;
      font-size: 0.75rem;
    }
    .payroll-cost-details-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: none;
      text-align: left;
      width: 100%;
      padding: 0;
      font-size: 0.875rem;

      &:after, &:focus, &:active {
        border: none;
        box-shadow: none;
        color: #0070ba;
      }

      &:hover {
        color: #003087;
      }

      &:hover::after, &:hover::before {
        background-color: #003087;
      }

      &::after, &::before {
        content: "";
        position: absolute;
        top: 0.5rem;
        width: 0.1875rem;
        height: 0.6125rem;
        background-color: #0070ba;
        transition: 0.2s ease-in-out;
      }

      &::after{
        left:8.1875rem;
        bottom: 0.625rem;
        transform: rotate(45deg);
      }

      &::before{
        left:8.5rem;
        bottom: 0.625rem;
        transform: rotate(-45deg);
      }
    }

    .payroll-cost-details-button[aria-expanded="true"] {
      &::after {
        transform: rotate(-45deg);
      }
      &::before {
        transform: rotate(45deg);
      }
    }
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    align-items: center;
  }

  .solid-divider {
    border-top: 0.0935rem solid black;

    &.half {
      width: 50%;
    }
  }

  .hidden-divider {
    border-top: none;
  }
}