@bank-icon_width: 48px;
@bank-icon_height: 30px;

.bank-icon {
    background-image: url('https://www.paypalobjects.com/ui-web/wallet-icons/bank-icons_sprite.png');
    background-repeat: no-repeat;
    display: block;
    width: @bank-icon_width;
    height: @bank-icon_height;
    background-position: -5px -285px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    margin-top: 0.2rem;

    &.generic_bank {
        background-position-y: -285px;
    }

    &.bank_of_america,
    &.bank_of_america_na {
        background-position-y: -5px;
    }

    &.barclays_bank {
        background-position-y: -45px;
    }

    &.capital_one_fsb {
        background-position-y: -85px;
    }

    &.citibank_fsb {
        background-position-y: -125px;
    }

    &.clydesdale_bank {
        background-position-y: -165px;
    }

    &.cooperative_bank {
        background-position-y: -205px;
    }

    &.fifth_third_bank {
        background-position-y: -245px;
    }

    &.hsbc_bank {
        background-position-y: -325px;
    }

    &.huntington_bank {
        background-position-y: -365px;
    }

    &.jpmorgan_chase_bank_na,
    &.chase_bank,
    &.chase {
        background-position-y: -405px;
    }

    &.lloyds_bank {
        background-position-y: -445px;
    }

    &.nationwide_bank {
        background-position-y: -485px;
    }

    &.natwest_bank {
        background-position-y: -525px;
    }

    &.pnc_bank {
        background-position-y: -565px;
    }

    &.rbs_bank {
        background-position-y: -605px;
    }

    &.regions_bank {
        background-position-y: -645px;
    }

    &.santander_bank {
        background-position-y: -685px;
    }

    &.standard_chartered_bank {
        background-position-y: -725px;
    }

    &.suntrust_bank {
        background-position-y: -765px;
    }

    &.td_bank,
    &.td_ameritrade_bank {
        background-position-y: -805px;
    }

    &.us_bank_na {
        background-position-y: -845px;
    }

    &.usaa_bank {
        background-position-y: -885px;
    }

    &.virgin_bank,
    &.virgin_money_bank {
        background-position-y: -925px;
    }

    &.wells_fargo_bank_na,
    &.wells_fargo_bk_na_wisconsin {
        background-position-y: -965px;
    }

    &.yorkshire_bank {
        background-position-y: -1005px;
    }

    &.japan_post_bank,
    &.mizuho_bank,
    &.mufg_bank,
    &.resona_bank,
    &.saitama_resona_bank,
    &.sumitomo_mitsui_banking_corp,
    &.みずほ銀行,
    &.三井住友銀行,
    &.ゆうちょ銀行,
    &.埼玉りそな銀行,
    &.りそな銀行,
    &.三菱ufj銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/wallet-icons/bank-icons-jp_localized.png');
        background-size: 58px 240px;
        background-position-x: -5px;
    }

    &.mizuho_bank,
    &.みずほ銀行 {
        background-position-y: -5px;
    }

    &.sumitomo_mitsui_banking_corp,
    &.三井住友銀行 {
        background-position-y: -45px;
    }

    &.japan_post_bank,
    &.ゆうちょ銀行 {
        background-position-y: -85px;
    }

    &.saitama_resona_bank,
    &.埼玉りそな銀行 {
        background-position-y: -125px;
    }

    &.resona_bank,
    &.りそな銀行 {
        background-position-y: -165px;
    }

    &.mufg_bank,
    &.三菱ufj銀行 {
        background-position-y: -205px;
    }
}

.bank-icon-large-func(@retinaSuffix: '') {
    background-repeat: no-repeat;
    width: 300px;
    height: 190px;
    background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/generic_bank.png');
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-size: contain;
    color: @vxDarkGray;

    &.bank_of_america,
    &.bank_of_america_na {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/bank_of_america_na@{retinaSuffix}.png');
    }

    &.barclays_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/barclays_bank@{retinaSuffix}.png');
    }

    &.capital_one_fsb {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/capital_one_fsb@{retinaSuffix}.png');
    }

    &.citibank_fsb {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/citibank_fsb@{retinaSuffix}.png');
    }

    &.clydesdale_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/clydesdale_bank@{retinaSuffix}.png');
    }

    &.cooperative_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/cooperative_bank@{retinaSuffix}.png');
    }

    &.fifth_third_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/fifth_third_bank@{retinaSuffix}.png');
    }

    &.hsbc_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/hsbc_bank@{retinaSuffix}.png');
    }

    &.huntington_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/huntington_bank@{retinaSuffix}.png');
    }

    &.jpmorgan_chase_bank_na {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/jpmorgan_chase_bank_na@{retinaSuffix}.png');
    }

    &.lloyds_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/lloyds_bank@{retinaSuffix}.png');
    }

    &.nationwide_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/nationwide_bank@{retinaSuffix}.png');
    }

    &.natwest_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/natwest_bank@{retinaSuffix}.png');
    }

    &.pnc_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/pnc_bank@{retinaSuffix}.png');
    }

    &.rbs_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/rbs_bank@{retinaSuffix}.png');
    }

    &.regions_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/regions_bank@{retinaSuffix}.png');
    }

    &.santander_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/santander_bank@{retinaSuffix}.png');
    }

    &.standard_chartered_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/standard_chartered_bank@{retinaSuffix}.png');
    }

    &.suntrust_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/suntrust_bank@{retinaSuffix}.png');
    }

    &.td_bank,
    &.td_ameritrade_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/td_bank@{retinaSuffix}.png');
    }

    &.us_bank_na {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/us_bank_na@{retinaSuffix}.png');
    }

    &.usaa_bank,
    &.usaa_federal_savings_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/usaa_bank@{retinaSuffix}.png');
    }

    &.virgin_bank,
    &.virgin_money_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/virgin_bank@{retinaSuffix}.png');
    }

    &.wells_fargo_bank_na,
    &.wells_fargo_bk_na_wisconsin {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/wells_fargo_bank_na@{retinaSuffix}.png');
    }

    &.yorkshire_bank {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/yorkshire_bank@{retinaSuffix}.png');
    }

    &.japan_post_bank,
    &.ゆうちょ銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/japan_post_bank@{retinaSuffix}.png');
    }

    &.mizuho_bank,
    &.みずほ銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/mizuho_bank@{retinaSuffix}.png');
    }

    &.mufg_bank,
    &.三菱ufj銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/mufg_bank_localized@{retinaSuffix}.png');
    }

    &.resona_bank,
    &.りそな銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/resona_bank@{retinaSuffix}.png');
    }

    &.saitama_resona_bank,
    &.埼玉りそな銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/saitama_resona_bank@{retinaSuffix}.png');
    }

    &.sumitomo_mitsui_banking_corp,
    &.三井住友銀行 {
        background-image: url('https://www.paypalobjects.com/ui-web/money-icons/bank/sumitomo_mitsui_banking_corp_localized@{retinaSuffix}.png');
    }
}

@media (min--moz-device-pixel-ratio: 1.5),
(-o-min-device-pixel-ratio: 3/2),
(-webkit-min-device-pixel-ratio: 1.5),
(min-device-pixel-ratio: 1.5),
(min-resolution: 1.5dppx) {
    .bank-icon {
        background-image: url('https://www.paypalobjects.com/ui-web/wallet-icons/bank-icons_2x.png');
        background-size: 58px 1040px;
    }
}