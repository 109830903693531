@import '../../node_modules/@swift-paypal/frontend-framework-paypal/dist/twbs-light/less/variables';
@import '../../node_modules/@swift-paypal/frontend-framework-paypal/dist/twbs-light/less/mixins';
@import '../../node_modules/@swift-paypal/frontend-framework-paypal/dist/twbs-light/less/responsive-utilities';
@import '../../node_modules/@swift-paypal/frontend-framework-paypal/dist/twbs-light/less/tooltip.less';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/vx-lib.css';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/core/_variables';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/core/_icon-font';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/core/_core';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/core/_normalize';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/type/_type';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/mixins/_transitions';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/global-nav/_global-nav';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/global-footer/_global-footer';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/grid/_grid';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/icons/_iconography';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/buttons/_buttons';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/anchors/_anchors';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/hr/_hr';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/tags/_tags';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/inputs-selects/_inputs-selects';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/checkboxes-radios/_checkboxes-radios';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/alerts/_alerts';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/panel/_panel';
@import '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/components/spinners/_spinners';
@import '../../node_modules/@swift-paypal/iconfont-consumer-paypal/dist/iconography';
@import (inline) '../../node_modules/@swift-paypal/vxpattern-lib-paypal/dist/paypal-sans-all.css';
@import "../../node_modules/@swift-paypal/pp-vx/dist/ppvx.css";
@import '../../src/styles/font';
@import '../../src/styles/variables';
@import '../../src/styles/global-styles';
@import '../../src/styles/_custom-selects';
@import '../../src/styles/global-pp-react-modifiers';
