@import "~@swift-paypal/pp-tokens/build/web/less/tokens.less";
@import (reference) "../../../styles/variables.less";

.forgiveness-application {
  @media @isTablet {
    margin-top: @ppui-spacing-xl;
  }
  
  .action-buttons {
    text-align: center;
    margin-top: 3rem;
  }
}
