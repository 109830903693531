@import (reference) "../../../src/static/styles.less";

.questionnaire-component {
  .questionnaire-heading-text {
    margin-bottom: 2rem;

    @media @isMobile{
      font-size: 1rem;;
    }

    .start-app-icon {
      position: relative;
      top: 1rem;
      margin-right: 0.875rem;

      @media @isMobile{
        top: 0.5rem;
        width: 1.875rem;
        margin-right: 0.25rem;
    }
    }
  }

  .application-steps {
    border-radius: 4px;
    background-color: @lightGrayBackground;
    padding: 0.5rem 0.25rem 0.5rem 1rem;

    .steps-img {
      margin: 1.5rem 0rem;
    }

    img {
      position: relative;
      top: 0.375rem;
      margin-right: 0.8125rem;
    }
  }

  &__button {
    min-width: 10rem;
  }
}

