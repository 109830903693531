@import (reference) "../../../../src/static/styles.less";

.forgiveness-customer-signoff{
  padding: 0;;

  h2 {
    margin-block-start: 0;
  }

  .agree-button {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}