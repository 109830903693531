.add-bank-accounts-form,
.verify-bank-accounts-form {
  .check-image {
    width: 320px;
    height: 160px;

    &.canada {
      width: 275px;
      height: 91px;
    }
  }

  .radioButtonHorizontal {
    flex-direction: row;
    gap: 5rem;
  }

  #isWithdrawalSameAsDepositRadio legend {
    color: rgb(44, 46, 47);
  }
}