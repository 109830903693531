.body-text {
  font-size: 0.9375rem;
  h4 {
    margin-bottom: 0;
    font-weight: normal;
    font-size: inherit;
  }
  p {
    margin-block-start: 0;
    font-size: inherit;
    &.upload-instructions {
      margin: 1rem 0 0.5rem 1.875rem;
      color: #9DA3A6;
    }
  }
  ol > li {
    font-size: inherit;
    list-style-type: upper-alpha;
  }
  ul > li {
    font-size: inherit;
    list-style-type: disc;
  }
  .underline {
    text-decoration: underline;
  }
}