@import (reference) "../../../../src/styles/global-styles.less";

.docusign-component {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 20px;

  .divider{
    border: none;
    border-top: 3px dotted @tertiaryLightGray;
    margin: 0;
    background-image: linear-gradient(
      to right,
      @tertiaryLightGray,
      @tertiaryLightGray 33%,
      transparent 34%,
    )
  }
}
