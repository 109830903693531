.pp-react {
  &__link--inline {
    font-size: inherit;
    line-height: inherit;
    padding: 0;
  }

  &__tooltip--inline {
    button {
      width: auto;
      height: auto;
      padding: 0 0 0 0.2rem;
      vertical-align: text-bottom;
    }
  }
  
  &__button--block {
    width: 100%;
  }
}