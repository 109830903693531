@import (reference) "../../../src/static/styles.less";

.custom-floating-point-select {
  .proper-arrow-spot {
    &::after {
      top: 24px;
    }
  }

  .force-show-label {
    &::before {
      z-index: 0;
    }
  }

  select.no-label-padding {
    padding: 12.5px 15px;
    color: @vxSecondaryGray;
  }
}

div[class*="vx_floatingLabel"].hasFocus {
  box-shadow: none;
}
