@import (reference) "../../../src/static/styles";

.edge-ie-margin-fix() {
  @media (max-width: @vxGridLgMediaQuery) {
    .vx_globalNav-main_mobile {
      margin-top: -23px !important;
    }

    .vx_hasOpenNav {
      .vx_globalNav-main_mobile {
        margin-top: 0 !important;
      }
    }
  }
}

.vx_hasOpenNav {
  .vx_globalNav-main_mobile {
    .vx_globalNav-toggleTrigger_animated {
      span {
        background-color: transparent;
        &:before,
        &:after {
          margin-top: 0;
        }
        &:before {
          .transform(rotate(45deg));
        }
        &:after {
          .transform(rotate(-45deg));
        }
      }
      .vx_globalNav-toggleTrigger_animated_open {
        display: none;
      }
      .vx_globalNav-toggleTrigger_animated_close {
        display: block;
      }
    }
    .vx_globalNav_mobile {
      position: static;
    }
  }
}

// fix for Edge
@supports (-ms-ime-align:auto) {
  .edge-ie-margin-fix()
}
// fix for IE11
@media (-ms-high-contrast:none){
  .edge-ie-margin-fix()
}

// if you are changing header height please update @headerHeight var as well
.portal_header {
  .vx_globalNav-displayName a {
    opacity: 1;
    color: @displayNameColor;
  }
  .vx_globalNav-links {
    white-space: nowrap;
  }
  .vx_globalNav-main.logged-out,
  .vx_globalNav-main_mobile.logged-out {
    background: @loggedOutHeaderBg;
    border-bottom: 1px solid @loggedOutHeaderBorder;

    .vx_globalNav-headerSection_trigger,
    .vx_globalNav-headerSection_actions {
      display: none;
    }
  }
  .vx_globalNav-link_notifications {
    .vx_notificationCount {
      border: 3px solid transparent;
    }
  }

  @media (max-width: @vxGridLgMediaQuery) {
    .vx_globalNav-headerSection_actions {
      width: 4em;
    }
  }
  .vx_globalNav-toggleTrigger_animated,
  .vx_globalNav-link_logout {
    border: none;
    &:hover {
      background-color: transparent;
      box-shadow: none;
      text-decoration: none;
    }
  }
  .vx_globalNav-headerSection_trigger {
    width: 4em;
  }
  .vx_globalNav-toggleTrigger_animated {
    margin: 0;
    position: relative;
    display: block;
    cursor: pointer;
    padding-top: 30px;
    min-height: 72px;
    max-height: 84px;
    /* hamburger menu */
    span {
      display: block;
      top: 0;
      width: 28px;
      height: 1px;
      margin: 0 auto;
      background-color: @dropdownBg;
      position: relative;
      &:after,
      &:before {
        display: block;
        content: "";
        position: absolute;
        width: 28px;
        height: 1px;
        background-color: @dropdownBg;
        .transitionMulti(transform 0.2s linear 0.2s, margin 0.2s linear 0.2s);
      }
      &:before {
        margin-top: -8px;
      }
      &:after {
        margin-top: 8px;
      }
    }
  }
  .vx_globalNav-toggleTrigger_animated_open,
  .vx_globalNav-toggleTrigger_animated_close {
    display: block;
    margin-top: 14px;
    left: 0;
    color: @vxWhite;
    font-size: 0.625rem;
    text-align: center;
    text-transform: uppercase;
  }

  .vx_globalNav-toggleTrigger_animated_close {
    display: none;
  }

  .dropdown-arrow {
    height: 15px;
    left: 2px;
    position: relative;
    top: 2px;
    width: 15px;
  }

  .dropdown {
    .dropdown-content {
      list-style-type: none;
      overflow: visible;
      position: absolute;
      background-color: @dropdownBg;
      top: 100%;
      right: 0;
      left: auto;
      transition: opacity 0.2s ease-in-out;
      margin: 0;
      z-index: 2;
      opacity: 1;
      height: auto;
      padding: 10px;
      border: 1px solid @dropdownBorder;
      @media (max-width: @smallScreen) {
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      .dropdown-header {
        color: #2c2e2f;
        font-size: 1em;
        text-transform: none;
        font-weight: 500;
      }

      li {
        display: block;
        cursor: pointer;
        width: 100%;
        float: left;
        padding: 0;
        clear: both;

        a {
          display: block;
          padding: 0;
          width: 100%;
          text-align: left;
          color: @dropdownText;
          font-size: 0.8em;
          font-weight: 300;
        }
      }
      @media (min-width: @smallScreen) {
        &:before {
          content: "";
          position: absolute;
          right: 10px;
          top: -10px;
          width: 10px;
          border-style: solid;
          border-width: 0px 10px 10px 10px;
          border-color: transparent transparent @dropdownBorder transparent;
        }
        &:after {
          content: "";
          position: absolute;
          right: 10px;
          top: -8px;
          width: 9px;
          border-style: solid;
          border-width: 0px 10px 10px 10px;
          border-color: transparent transparent @dropdownBg transparent;
        }
      }
    }

    &.activity {
      .dropdown-content {
        max-width: 350px;
        min-width: 200px;
        padding: 0;
        .business {
          margin: 0;
          .business-name {
            padding-left: 4px;
            border-bottom: 1px solid @dropdownSeparator;
            white-space: nowrap;
            .ellipsify();
          }
          .loan-details {
            font-size: 0.75rem;
            color: @loanDetails;
            padding: 10px 0;

            div {
              padding-left: 12px;
            }
          }
          li {
            padding: 0 10px;
            &:not(:last-child) .loan-details {
              border-bottom: 1px solid @dropdownSeparator;
            }
          }
        }

        @media (max-width: @vxGridMdMediaQuery) {
          li {
            text-align: left;
          }
          .business {
            .loan {
              padding: 0;
            }
            .loan-details {
              padding-left: 2.5em;
            }
            .business-name {
              padding-left: 1.2em;
            }
          }
        }
      }
    }
  }

  .focus-highlight {
    &:focus {
      outline: thin solid @vxWhite;
      outline-offset: 0;
    }
  }

  .logo-focus-highlight {
    &:focus {
      border: thin solid @highLightPurple;
      outline: thin solid @vxWhite;
      outline-offset: 0;
      border-radius: 3px;
    }
  }
}
