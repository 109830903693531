@import (reference) "../../../src/styles/variables.less";

.table-cell-logo-styles {
  display: block;

  .scalable-image {
    max-width: 100%;
    height: auto;
  }
}

.logo-container{
  @media (max-width: @maxTablet) {
    margin: 0 auto;
  }

  @media (max-width: @maxPhone) {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-top: 2rem;
    }
  }

  padding: 0.625rem 0 0.625rem 0;
    a {
      line-height: 0.5rem;
    }
  .vx_globalNav-brand_desktop,
  .vx_globalNav-brand_mobile {
    padding: 1rem;

    &:before,
    &:after {
      content: "";
      padding: 0;
    }

    @media (max-width: @maxPhone) {
      .table-cell-logo-styles();
    }

    @media (max-width: @maxTablet) {
      &.unauthorized {
        .table-cell-logo-styles();
      }
    }
  }

  .ppp-logo {
    max-height: 32px;
  }
}

.vx_globalNav-container {
  @media (max-width: @maxPhone) {
    .logo-container {
      display: none;
    }
  }
}
