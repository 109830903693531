@import (reference) '../../../../src/static/styles.less';

.change-password {
  .change-password-form {
    .margin-bottom-sm;
    margin-top: 18px;

    @media (max-width: @maxPhone) {
      margin-top: 1.5px;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
