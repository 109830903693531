@import (reference) "../../../src/styles/variables.less";

.login {
  .auth-alert-box {
    width: 83%;
  }

  @media @isDesktop {
    .paypal-login {
      margin: auto;
    }
  }

  @media @isTabletOrMobile {
    text-align: center;
    margin: 0 auto;
    max-width: 25rem;
  }
}
