@import (reference) "../../../../src/static/styles.less";

.inTextToolTip {
    margin-right: -.4rem;
}

.forgivenessListItems {
  li {
    margin-bottom: 1.5rem;
  }
}

.alignWithList {
  margin-left: 1.5rem;
}
