@import "~@swift-paypal/pp-tokens/build/web/less/tokens.less";

.other-business-details {
  .input-field {
    margin: @ppui-spacing-md 0 @ppui-spacing-xl 0;
  }
  .eidlLoanNumberField {
    padding-bottom: 0.1rem;
  }
}
