@import (reference) '../../../../static/styles.less';


.upload-section {
  padding: 1rem 1rem 2rem;
  background-color: @lightGrayBackground;


  .helper-text {
    margin-bottom: 1rem;
  }

  .text {
    color: #626264;
  }
}
