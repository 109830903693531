@import (reference) '../../../../src/static/styles.less';

.progress-bar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 2.25rem;

  &.ppvx_col-1 {
    max-width: 1.5rem;
  }

  &.active {
    color: @ppui-color-green-500;
    background-color: @ppui-color-green-100;
  }

  &.in-progress {
    color: @ppui-color-green-500;
    background-color: @ppui-color-green-100;
    border-radius: 0 0 1rem 1rem;
    margin-bottom: -0.75rem;
    z-index: 10;
  }

  &.inactive {
    color: #cbd2d6;
    background-color: @ppui-color-grey-100;
  }

  &.progress-begin {
    padding-top: 0.5rem;
    border-radius: 1rem 1rem 0 0;
  }

  &.progress-end {
    padding-bottom: 0.5rem;
    border-radius: 0 0 1rem 1rem;
  }


  .in-progress-icon {
    height: 0.875rem;
    width: 0.875rem;
    margin-left: 0.1475rem;
  }

  .circle_empty {
    height: 1.17rem;
    width: 1.17rem;
    color: @ppui-color-grey-700;
    background-color: @ppui-color-grey-100;
  }
}

.progress-text {
  line-height: 1rem;
  padding-top: 2.25rem;

  &.stage-begin {
    padding-top: 0.5rem;
  }
}

.light-grey {
  color: @ppui-color-grey-500;
}

.progress-tracker {

  &__summary-title {
    padding: 0.75rem;
  }

  &__loan-terms {
    width: 100%;

    &.margin-bottom-override {
      margin-bottom: 0;
    }
  }

  &__tile {
    border-top:4px;
    border-top-style: solid;
    border-top-color: #009cde;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
  }

  &__layout {
    &--vertical {
      .ppvx_row {
        flex-wrap: nowrap;
      }
    }

    @media @isDesktop {
      &--horizontal {
        display: none;
      }
    }
    
    @media @isTabletOrMobile {
      &--vertical {
        display: none;
      }
    }
  }
}