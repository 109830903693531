@import (reference) "../../../../src/static/styles.less";
.action-items {
  .stipulation {
    .center-align();
    height: 91px;
  }

  .fulfilled-container {
    width: 145px;
    margin: 0;
  }
  .fulfilled {
    color: @vxLightGreen;
    font-weight: bold;
  }

  .list-items .item {
    padding: 0.55em 0 0.55em 1em;

    &:nth-of-type(odd) {
      background-color: @vxLighterGray;
    }
  }

  .icon-align {
    .center-align();
  }

  .upload-icon {
    background: url('../../../static/images/icons/upload.svg') no-repeat;
    width: 40px;
    height: 25px;
  }

  .fixed-btn {
    margin: 0;
    width: 136px;
  }
}
