@import (reference) '../../../src/static/styles.less';

.settings {
  .address {
    margin-bottom: 42px;
  }
}

.change-security-questions-form {
  .question-one {
    padding-bottom: 55px;
  }

  .question-two {
    padding-bottom: 38px;
  }
}
