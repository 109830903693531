.spinner-standalone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 6.5rem - 12.5rem);

  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 12.5rem);
  }
}
