@import (reference) "../../../../src/styles/global-styles.less";

@icon-size-normal: 1.5rem;
@icon-size-larger: 1.7rem;
@icon-size-gigantic: 4.7rem;

.secondary-gray {
  color: @vxSecondaryGray
}

.upload-single-document-container {
  padding: 1.2rem;

  &.upload-area-collapsed {
    background-color: @vxWhite;
  }
}

.show-border-top {
  border-top: 0.0625rem solid @vxLightGray;
}

.show-border-bottom {
  border-bottom: 0.0625rem solid @vxLightGray;
}

.title-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .vx_text-body_secondary {
    @media (max-width: @maxPhone) {
      text-align: left;
    }

    .vx_icon-positive-large {
      font-size: @icon-size-larger;
      font-weight: bold;
    }

    .vx_icon-critical-large {
      font-size: @icon-size-normal;
      font-weight: bold;
    }
  }

  .icon-arrow {
    font-size: @icon-size-normal;
    margin-top: 0.3rem;
    margin-right: 0.4rem;
  }
}

.name-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a:hover, a:focus {
    text-decoration: none;
    color: @vxSecondaryGray;
  }
}

.single-document-dropzone-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  min-height: 150px;
  width: 100%;
  border: 2px #9DA3A6;
  border-style: dashed;
  border-radius: 4px;
  background-color: @vxWhite;
  outline: none;

  img {
    margin-top: -0.5rem;
  }
}

.single-document-dropzone-area:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  min-height: 150px;
  width: 100%;
  border: 2px @payPalDarkBlue;
  border-style: dashed;
  border-radius: 4px;
  background-color: @vxWhite;
  outline: none;
}

.file-info-container {
  margin-top: .5rem;
  min-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.display-none {
    display: none;
  }

  div {
    a {
      width:90px;
      display: block;
      text-align: left;
      .vx_text-body-sm_medium;
    }
  }
}

.fulfilled {
  color: @vxLightGreen;
  font-weight: bold;
}

.fulfilled-container {
  cursor: default;
  padding-left: 30px;
}

.fulfilled-container .button-link {
  cursor: default;
}

.invoice {
  margin-top: -12px;
  line-height: unset;
}

.cloud {
  height: 50px;
  width: auto;
}

.single-document-dropzone-area {
  .fulfilled,
  .invoice {
    font-size: @icon-size-gigantic;
  }

  .fulfilled {
    font-weight: bold;
  }
}

// fix for IE11, pixel-perfect
@media (-ms-high-contrast:none){
  .dropzone-file-info-container {
    margin-top: 30px;

    .dropzone-file-name {
      margin-top: 40px;
    }
  }
}
