@import (reference) "../../../../styles/variables.less";

.forgiveness-stage-messaging {
  background-color: @ppui-color-grey-100;
  border-radius: @ppui-border-radius-sm;
  margin: @ppui-spacing-xl-2 0;
  padding: @ppui-spacing-xl @ppui-spacing-xl;

  &__button {
    margin-top: 0.25rem;
    min-width: 10rem;
  }
}
