@import (reference) "../../../src/styles/variables.less";

@footer-font-size: 0.7rem;

div.portal-footer {
  margin: 0;
  min-height: @footerHeightMobile;

  .footer-release-version {
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    line-height: 24px;
  }

  @media @isMobile {
    min-height: @footerMinHeight;
  }


  .footer-margin {
    margin-top: 1rem;
    padding: 0;

    @media @isMobile {
      margin: 0.5rem 0;
    }
  }

  .first-line {
    font-size: @footer-font-size;
    padding: 0.4rem 0.75rem;
    line-height: 24px;
  }

  .padding-left-sm {
    padding-left: 5px;
  }

  .stacked {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      font-size: @footer-font-size;
      padding: 0.4rem 0.75rem;
      line-height: 24px;

      a {
        text-transform: none;
        font-size: @footer-font-size;
        padding: 0;

        &:visited {
          color: @vxSecondaryGray;
        }
      }

      span {
        display: inherit;
      }
    }

    @media @isTabletOrMobile {
      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        padding: 0.4rem 0.6rem;
      }
    }
  }

  .footer-vertical-line {
    &:after {
      content: "\7c";
      display: inline-block;
      vertical-align: -0.4rem;
      font-weight: 300;
      font-size: 1.2rem;
    }

    @media @isTabletOrMobile {
      display: none;
    }
  }

  .ca-customer-support-phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}