.add-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 1.25rem 0;
}


.heading {
    text-align: center;
    margin-bottom: 3.5rem;
    margin-top: 1rem;
}

.cheque-icon {
    width: 100%;
}