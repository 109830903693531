// TODO: DELETE THIS FILE AND USE STANDARD VXLIB FILE IF FIX GETS MERGED

/* Container used for styling the custom select */
.vx_select {
    position: relative;
    display: block;
}
/* This is the native select, we're making everything but the text invisible so
* we can see the button styles in the wrapper */
.vx_select select {
    width: 100%;
    margin: 0;

    &:focus {
        outline: none;
    }
}
/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
* etc. or the arrow could just baked into the bg image on the select. */
.vx_select:after {
    font-family: @vxIconIdentifier;
    speak: none;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #icon-arrow-down-small(); // down-arrow icon
    position: absolute;
    top: 50%;
    right: @vxDefaultFormControlHeight / 8;
    z-index: 2;
    /* These hacks make the select behind the arrow clickable in some browsers */
    pointer-events: none;
    display: none;
}
/* csslint ignore:start */
@supports ( -webkit-appearance: none ) or ( appearance: none )
    or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
  /* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
    * @supports ( mask-type: alpha ) is Firefox 35+
    */

    /* Show custom arrow */
    .vx_select:after {
        display: block;
    }
    /* Remove select styling */
    .vx_select select {
        padding-right: 2.75rem;
        /* inside @supports so that iOS <= 8 display the native arrow */
        background: @vxInputBgColor;
        /* inside @supports so that Android <= 4.3 display the native arrow */
        border: 1px solid @vxInputBorderColor;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .vx_select select:focus {
        border-color: @vxInputBorderActiveColor;
        box-shadow: 0 0 1px 0 @vxInputBorderActiveColor;
    }
    .vx_has-error .vx_selectIcon_error:before {
        right: 2.2rem;
    }
    .vx_has-error select {
        padding-right: 4.5rem;
    }

    /*********************************
    *       FLOATING LABEL          *
    *********************************/

    .vx_floatingLabel {
        select {
            position: relative;
            z-index: 1;

            height: @vxDefaultFormControlHeight;
            padding: 18px 15px 0;

            background: transparent;
            color: @vxDarkGray;
        }
        .vx_select:before {
            position: absolute;
            top: @vxFloatedLabelControlActiveTop;
            left: 0;
            font-size: @vxFloatedLabelControlActiveFontSize;
            color: @vxSecondaryGray;
            font-weight: @vxFloatedLabelControlFontWeight;
            content: attr(data-label-content);
            padding: 0 15px;
            line-height: @vxDefaultFormControlHeight;
            margin: 1px;
            z-index: 0;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
            color: @vxSecondaryGray;
        }
        &.vx_has-error .vx_select:before {
            color: @vxInputTextErrorColor;
        }
        &.hasFocus {
            .vx_select:before,
            .vx_select:after {
                color: @vxAccessibleBlue;
            }
        }
    }
}
/**
 * Hacks to adjust alignment for Firefox and Edge
 */
@supports ( ( -moz-appearance: none ) and ( mask-type: alpha ) )
    or (-ms-ime-align: auto) {
    .vx_floatingLabel {
        select {
            padding-left: 11px;
        }
    }
}

/* This is a fix for the IOS input focus zoom issue */
.vx_floatingLabel_active {
    input {
        font-size: 1rem;
        &:before {
            font-size: 1rem;
        }
    }
}