@import (reference) "../../../../styles/variables.less";

.stipulations-list {
  border: @ppui-border-width-xs solid @ppui-color-grey-200;
  border-radius: @ppui-border-radius-sm;
  margin: @ppui-spacing-xl-2 0;
  padding: @ppui-spacing-xl @ppui-spacing-xl-2;

  &__divider {
    border-color: @ppui-color-grey-200;
    margin: @ppui-spacing-xl 0;
  }

  @media @isSmallMobile {
    border-color: @ppui-color-grey-300;
    border-left: none;
    border-right: none;
    padding: @ppui-spacing-xl;
  }
}