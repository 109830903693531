@import (reference) "../../../src/styles/variables.less";

.login-revamp {
    padding: 2.5rem 0 0;
    @media @isMobile{
        margin: auto;
    }

    .login-title{
        font-size: @ppui-size-line-300;
        font-weight: @ppui-font-weight-light;
        margin: 1rem 0rem;
    }

    .login-option{
        text-align: left;
        margin:auto;
        cursor: pointer;
        .text{
            font-size: @ppui-size-line-100;
            margin: 0;
            font-weight: @ppui-font-weight-light;
        }
        .button {
            all: unset;
        }
    }

    .caption{
        color: @tertiaryLightGray;
        font-size: @ppui-font-size-sm;
    }

    .login-tile{
        width: 21.875rem;
        height:25rem;
        margin-left: auto;
        align-content: center;
        @media @isSmallMobile{
            width:90vw;
        }
    }

    .login-caption{
        padding-bottom: .5rem;
    }

    .swift-link{
        overflow: auto;
        font-size: @ppui-font-size-sm;
        position: absolute;
        bottom: 0;
        width: 80vw;
        left: 50%;
        margin-left: -40vw;
        @media @isMobile{
            position: relative;
            margin-top: 5rem;
        }
    }

    .logo{
        width: 6.25rem;
    }

    .icon{
        width: 2.5rem;
        cursor: pointer;
    }

    .caption-container{
        max-height: 7.25rem;
    }

    .alert{
        font-size: @ppui-font-size-sm;
        line-height: 1rem;
    }
}

.loan-builder-revamp{
    align-content: center;
    padding-bottom: 0.5rem;
    .login-title{
        margin: 0;
        font-size: @ppui-font-size-xl;
    }
    .login-tile{
        padding: 0px 1.5rem 1.5rem 1.5rem;
        margin-top: 1rem;
        height: 100%;
        width: 23rem;
        @media @isSmallMobile{
            width:90vw;
        }
    }
    .option-caption{
        padding: 0;
        margin: 0;
        }

    .vx_anchor{
        cursor: pointer;
        border: none;
        background-color: transparent;
        outline: none;
    }

    .login-button{
        font-size: @ppui-font-size-sm;
        padding: .4rem;
        width: 50%;
    }
}
