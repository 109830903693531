@import (reference) "../../../../../src/static/styles.less";
@import (reference) "../../../../styles/variables.less";

.forgiveness-tile {
  border: @ppui-border-width-xs solid @ppui-color-grey-200;
  border-radius: @ppui-border-radius-sm;
  margin: @ppui-spacing-xl-2 0;
  padding: @ppui-spacing-lg;

  @media @isMobile {
    border: none;
    border-radius: 0;
    border-top: @ppui-border-width-xs solid @ppui-color-grey-200;
    margin: @ppui-spacing-xl-2 0;
    padding: @ppui-spacing-xl 0 0 0;
  }

  &__body {
    padding: 0 @ppui-spacing-md @ppui-spacing-sm @ppui-spacing-md;
    .apply-now-btn {
      min-width: 7.5rem;
    }

    p {
      font-size: @ppui-font-size-md;
    }

    @media @isMobile {
      padding: 0 0 @ppui-spacing-xl 0;
    }
  }

  &__header {
    display: inline-flex;

    .heart-in-hand {
      height: @ppui-line-height-xl-5;
      margin-right: @ppui-spacing-lg;
    }

    .ppp-fund-text {
      align-self: center;
    }

    @media @isMobile {
      flex-wrap: wrap;
      line-height: @ppui-line-height-lg;
      justify-content: center;
      text-align: center;

      .heart-in-hand {
        margin: 0 0 @ppui-spacing-xl 0;
        width: 100%;
      }
    }
  }
}
