@import "@swift-paypal/pp-vx/dist/core/fonts.less";

@font-face {
  font-family: PayPal-Sans;
  font-style: normal;
  src: url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansSmall-Regular.woff2') format('woff2'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansSmall-Regular.woff') format('woff'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansSmall-Regular.eot') format('embedded-opentype'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansSmall-Regular.svg') format('svg');
}

@font-face {
  font-family: PayPal-Sans-Big;
  font-style: normal;
  src: url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansBig-Light.woff2') format('woff2'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansBig-Light.woff') format('woff'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansBig-Light.eot') format('embedded-opentype'),
  url('https://www.paypalobjects.com/digitalassets/c/paypal-ui/fonts/PayPalSansBig-Light.svg') format('svg');
}
