@import (reference) "../../../../src/static/styles.less";
@import (reference) "../../../styles/variables.less";

.forgiveness-detail {
  &__heading-container {
    display: flex;

    .heading-illustration {
      margin: 0 @ppui-spacing-xl 0 0;
      max-height: 7rem;
    }

    .titles {
      align-self: center;
    }

    @media @isSmallMobile {
      flex-wrap: wrap;

      .heading-illustration {
        margin: 0 auto @ppui-spacing-lg auto;
      }

      .fully-forgiven {
        text-align: center;
      }
    }
  }

  &__subtitle {
    padding-top: @ppui-spacing-xl;
  }

  &__messaging {
    background-color: @ppui-color-grey-100;
    border-radius: @ppui-border-radius-sm;
    display: flex;
    justify-content: space-between;
    margin-top: @ppui-spacing-xl-3;
    padding: @ppui-spacing-xl @ppui-spacing-xl;
 
    .label {
      flex-wrap: wrap;
      font-size: @ppui-size-text-100;
    }
    
    .value {
      font-size: @ppui-size-text-100;
      padding: 0 @ppui-spacing-xl 0 @ppui-spacing-xl-2;
    }
  }
}

