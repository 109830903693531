.paypal-login {
  .btn-squared {
    border-radius: 4pt;
    height: 55px;
    width: 286px;
    padding: 0;

    .paypal-login-text {
      padding-left: 14px
    }
  }
}
