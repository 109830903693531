@import (reference) '../../../src/styles/variables.less';

.ownership-form {
  form {
    margin-bottom: 3.33334em;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .ownership-form-close {
    border: none !important;
    background: transparent;
    margin: 10px 0 -10px 0;
  }
}

#ownership-action-item-form, #additional-signer-form {
  .ownership-terms,
  .ownership-attestation,
  .ownership-controlling-manager {
    .vx_checkbox.vx_has-error:after,
    .vx_radio.vx_has-error:after {
      display: none;
    }
  }
  .signer-margin-top {
    margin-top: 2rem;
  }
}

.ownership-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.vx_panel-well {
    padding: 0.75rem 0.5rem;
  }
  .vx_panel-info {
    max-width: 100%;
    display: inline-block;
  }
  .vx_panel-header {
    font-weight: 300;
    font-size: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .vx_panel-text {
    font-weight: 300;
    font-size: .86667em;
    em {
      color: @vxAccessibleBlue;
    }
  }

  .action-container {
    width: 150px;
    justify-content: flex-end;
  }
  .vx_panel-action {
    display: inline-block;
    max-width: inherit;
    min-width: 71px;
    margin-top: 17px;
    line-height: 13px;
    font-weight: 300;
    font-style: italic;

    &.remove {
      text-align: right;
      max-width: 71px;

      &:hover, &:active, &:focus {
        color: @vxAccessibleBlue;
        text-decoration: underline;
      }
    }
    &.edit {
      min-width: 50px;
      max-width: 50px;
      text-align: left;
      float: inherit;

      &:hover, &:active, &:focus {
        color: @vxAccessibleBlue;
        text-decoration: underline;
      }

      &.show-border {
        border-left: 1px solid @vxGray;
        float: unset;
      }
    }
  }
}
