@import (reference) "../../../styles/variables.less";

.summary-page-layout {
  min-height: calc(100% - 9rem);
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  .summary-page-layer {
    @media @isDesktop {
      margin-left: @marginCompensation;
      margin-right: @marginCompensation;
    }

    &:nth-child(odd) {
      background: @ppui-color-white;
    }
  
    &:nth-child(even) {
      background: @ppui-color-grey-100;
    }

    &__content {
      margin: auto;
      max-width: @maxPageWidth;
      padding: 2rem;

      @media @isSmallMobile {
        padding: 2rem 1rem;
      }
      
      .card-container {
        padding: 0;
      }
    }
  }
}
