@import (reference) '../../../../src/styles/variables.less';

.editable-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.vx_panel-well {
    padding: 0.75rem 0.5rem;
  }
  .vx_panel-info {
    max-width: 100%;
    display: inline-block;
  }
  .vx_panel-header {
    font-weight: 300;
    font-size: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .vx_panel-text {
    font-weight: 300;
    font-size: .86667em;
    em {
      color: @vxAccessibleBlue;
    }
  }

  .vx_panel-action {
    display: inline-block;
    max-width: inherit;
    min-width: 71px;
    margin-top: 17px;
    line-height: 13px;
    font-weight: 300;
    font-style: italic;

    &.edit {
      min-width: 50px;
      max-width: 50px;
      text-align: left;
      float: inherit;

      &.show-border {
        border-left: 1px solid @vxGray;
        float: unset;
      }
    }

    &.one-line-edit {
      margin-top: 7px;
    }
  }
}
