@import (reference) "../../../../src/static/styles.less";
@import (reference) "../../../styles/variables.less";

.apply-for-ppp-loan-tile {
  background-color: @ppui-color-grey-100;
  border: @ppui-border-width-xs solid @ppui-color-grey-200;
  border-radius: @ppui-border-radius-sm;
  padding: @ppui-spacing-xl-2;
  margin: @ppui-spacing-xl 0;

  @media @isSmallMobile {
    border: none;
    border-radius: 0;
    border-top: @ppui-border-width-xs solid @ppui-color-grey-200;
  }

  &__apply-now {
    margin-top: @ppui-spacing-xl;
    max-width: 14rem;
    width: 14rem;

    @media @isSmallMobile {
      width: 100%;
    }
  }

  &__details {
    background-color: @ppui-color-grey-100;
    border-radius: @ppui-border-radius-sm;
    padding: @ppui-spacing-md 0;

    .detail {
      display: flex;
      flex-wrap: wrap;
      padding: @ppui-spacing-md @ppui-spacing-lg;

      .detail-label {
        font-weight: @ppui-font-weight-normal;
      }

      .areYouEligibleText {
        font-weight: bold;
        padding-bottom: 0.5rem;
      }

      .linkApplyContainer {
        padding: 0rem;
        .formLinkDiv {
          padding-top: 2.5rem;
        }
      }

      .form-link {
        padding-bottom: 0rem;
      }

      .formLinkEzDiv {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 0rem;
        .formLinkEz {
          padding-top: 0rem;
          padding-bottom: 0rem;
        }
      }

      @media @isMobile {
        .detail-value {
          width: 100%;
        }
      }

      .detail-tooltip {
        button {
          font-size: @ppui-font-size-md;
          height: 1.25rem;
          padding: 0 0 0 0.3rem;
          width: 1.25rem;
        }
      }

      .dot-leader {
        background-position: bottom;
        height: 1rem;
        flex-grow: 2;
        margin: 0 @ppui-spacing-sm;

        @media @isMobile {
          display: none;
        }
      }
    }
  }

  &__eligible {
    display: inline-flex;
    font-size: @ppui-font-size-md;
    font-weight: bold;
    line-height: @ppui-line-height-sm;
    padding-bottom: @ppui-spacing-xl-2;

    .heart-in-hand {
      height: @ppui-line-height-xl-5;
      margin-right: @ppui-spacing-lg;
    }

    .ppp-fund-text {
      align-self: center;
      font-size: @ppui-font-size-xl;
      font-weight: bold;
    }

    @media @isMobile {
      flex-wrap: wrap;
      line-height: @ppui-line-height-lg;
      justify-content: center;
      text-align: center;

      .heart-in-hand {
        margin: 0 0 @ppui-spacing-xl 0;
        width: 100%;
      }
    }
  }

  &__ineligible {
    padding-bottom: @ppui-spacing-xl-2;

    &-date {
      font-family: inherit;
      font-size: @ppui-font-size-xl;
      font-weight: 400;
      padding-top: @ppui-spacing-lg;
    }
  }

  &__learn-more {
    padding-bottom: @ppui-spacing-xl;
  }

  &__form-heading {
    padding-top: 1rem;
  }
}
