@import (reference) "../../../../styles/variables.less";

.start-2021-forgiveness-3508s {
  background-color: @ppui-color-grey-100;
  border-radius: @ppui-border-radius-sm;
  margin: @ppui-spacing-xl-2 0;
  padding: @ppui-spacing-xl @ppui-spacing-xl;

  p {
    font-size: @ppui-font-size-md;
  }
}
