@import (reference) "../../../../src/styles/global-styles.less";

.upload-spinner {
  position: static;
  overflow: scroll;
  height: 100vh;

  &:before {
    z-index: 1100;
  }

  &:after {
    z-index: 1200;
  }

  .info-text {
    position: absolute;
    top: 60%;
    width: 100%;
    text-align: center;
    z-index: 1300;
    color: @vxSecondaryGray;
    animation: vx_fade-in 0.3s ease-out 0s both;

    @media (max-height: 650px) {
      top: 70%;
    }
  }
}

