@import '~@swift-paypal/pp-vx/dist/core/utilities/variables.less';

.total-costs-fte-reduction-text {
  background-color: @ppui-color-grey-100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem 1rem;

  .row {
    display: flex;
    flex-direction: row;

    .text {
      padding: 0.875rem 0;
      flex-grow: 0;
    }

    .sign {
      min-width: 2rem;
      text-align: center;
    }

    .amount {
      min-width: 7rem;
      text-align: right;
    }

    .dot-leader {
      background-position: bottom;
      height: 1rem;
      flex-grow: 1;
      margin: 0 0.375rem;
      padding: 1rem 0 0 0;
    }

    .caption {
      color: @ppui-color-grey-600;
      padding-top: 1rem;
    }

    .total {
      border-top: solid @ppui-color-black 0.075rem;
    }

    .link-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: none;
      display: inline;
      margin: 0 0 0.5rem 0;
      padding: 0;

      &:hover, &:active, &:focus {
        text-decoration: underline;
      }
    }
  }
}
