@import (reference) '../../../../src/static/styles.less';

.loan-terms {
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  .loan-amount {
    align-items: center;
    display: flex;

    &__badge {
      height: 0.875rem;
      margin-top: 0.125rem;
    }

    &__text {
      font-size: 1.5rem;
      font-weight: 400;
      font-family: 'PayPal-Sans', Helvetica Neue, Arial, sans-serif;
      line-height: 1.875rem;
      padding-right: 0.5rem;
    }
  }

  .open-application-toggle {
    padding-top: 1rem;

    .open-application-button {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
    }
  }

  .details-toggle {
    margin-left: -0.25rem;
    padding-top: 1rem;
    text-align: left;

    .show-details-button {
      border: none;
      padding: 0;
      transition: none;
      &:hover, &:active, &:link,
      &:visited, &:focus {
        background-color: transparent !important;
        color: #0070ba;
        transition: none;
        text-decoration: none;
      }
    }
  }

  .detail-header {
    color: @vxSecondaryGray;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .loan-amount-label {
    color: @vxSecondaryGray;
  }
  .loan-term-label {
    color: @vxSecondaryGray;
  }
  .term-details {
    margin-top: 1rem;
  }
  .loan-details {
    .vx_text-body-md;
    line-height: @ppui-line-height-sm;

    .detail {
      display: flex;
      color: @vxSecondaryGray;
      margin-top: 1.25rem;
      .loan-details-amount {
        font-weight: 500;
      }
      .dot-leader {
        .vx_hr-alt;
        background-position: bottom;
        height: 1rem;
        flex-grow: 2;
        margin: 0 0.25rem;
      }
    }
  }
  .existing-loan {
    background: @lightGrayBackground;
  }
  .footnote {
    color: @vxSecondaryGray;
    font-size: 0.625rem;
  }
}
