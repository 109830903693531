/* autoprefixer grid: autoplace */
@import "../../styles/variables.less";

.better-standard-layout {
  display: grid;
  grid-template-columns: 34% 64%;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    "sidebar body"
    "secondary-sidebar body"
    "blank body";

  @media @isTabletOrMobile {
    grid-template-columns: 100%;
    grid-template-areas:
      "sidebar"
      "body"
      "secondary-sidebar"
  }

  &--body-only {
    grid-template-columns: 100%;
    grid-template-areas:
      "body"
  }

  .sidebar {
    grid-area: sidebar;
  }

  .secondary-sidebar {
    grid-area: secondary-sidebar;
  }

  .body {
    grid-area: body;
  }

  // This isn't used but prevents autoprefixer from throwing a warning on build
  .blank {
    grid-area: blank;
  }
}
