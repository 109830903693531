@import (reference) "../../../src/styles/global-styles.less";

.form-message {
  .vx_form-control-message;
  .margin-bottom-sm;
  background: @vxAccessibleDarkerBlue;
  color: @vxWhite;
  .unround-corners;

  .message-icon {
    .vx_icon;
    .vx_icon-medium;
    color: @vxWhite;
    .pull-right;

    &:before {
      font-weight: 600;
      line-height: 1rem;
    }
  }
}
