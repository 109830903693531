@import (reference) "../../../styles/variables.less";

.payment-page-layout {
  min-height: calc(100% - 9rem);
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 2rem 0;

  .payment-header {
    padding: 2rem 0;
  }

  .responsive-ach-offset {
    @media @isMobile {
      padding-bottom: 1rem;
      padding-left: .5rem;
    }
  }

  .loan-dropdown-wrapper {
    margin: .5rem;
  }

  .payment-tile-wrapper {
    // width: 60%;
    margin: .5rem;

    .payment-tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      .payment-btn {
        margin: 0.625rem 0;
      }
    }
  }

  .scheduled-payment-wrapper {
    padding: 2rem 0;

    .scheduled-payment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .transactions-table {
      display: flex;
      flex-direction: column;

    }

    .empty-table {
      justify-content: center;
      align-items: center;
      min-height: 6rem;
    }


    .transaction-card-wrapper {
      margin: .5rem;

      .transaction-col-0 {
        text-align: left;

        @media @isMobile {
          margin-bottom: .5rem;
        }
      }

      .transaction-col-3 {
        text-align: right;

        @media @isMobile {
          margin: auto;
        }

      }

      .transaction-type {
        margin-bottom: 0.5rem;
      }

      .transaction-date {
        margin-bottom: 0.25rem;
      }

      .transaction-account-info {
        display: flex;
        gap: .5rem;
        align-items: center;
      }

      .transaction-status-container {
        margin-top: .5rem;
      }
    }

    .cancel-payment-text {
      margin: 2rem auto;
    }
  }

}

.error-view-flex {
  height: 36rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.stop-svg {
  width: 10rem;
  height: 10rem;
}

.payment-bg {
  background: @culturedWhiteBackground;
}

.transaction-date-wrapper {
  @media @isMobile {
    margin-bottom: .5rem;
  }
}