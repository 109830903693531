@import (reference) "../../../../src/static/styles.less";

.bfe-tile {
  .bfe-content {
    @media screen and (max-width: @maxPhone) {
      padding-top: 9px;
    }

    .bfe-text {
      color: inherit;
      font-family: inherit;
      margin: 0;
    }

    #bfe-inline {
      display: inline-block;
      margin-left: 1.25rem;
      position: absolute;
    }

    .bfe-name {
      font-size: 1.125rem;
      color: #2c2e2f;
      font-weight: bold;
      font-family: inherit;
    }
  }
}
