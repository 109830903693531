@import (reference) "../../../../src/static/styles.less";

.login-form {
  .fluid-right-margin {
    margin-right: 4rem;

    @media screen and (max-width: @maxTablet) {
      margin-right: 0;
    }
  }

  .loan-builder-login {
    background: @continueWithLoanBuilderButton;
    height: 55px;
    width: 286px;

    &:hover,
    &:focus,
    &:active {
      transition: none;
      background: @vxPayBlue;
      border-color: @vxPayBlue;
    }

    .lb-logo {
      background: url('../../../static/images/icons/lb-mini.svg') no-repeat top left;
      width: 30px;
      height: 30px;
    }

    .loan-builder-login-text {
      vertical-align: top;
      padding-top: 3px;
      padding-left: 14px;
    }
  }

  .forgot-password-margin {
    margin-left: 16px;
    font-weight: 400;
  }
}
