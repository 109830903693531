@import (reference) '../../../static/styles.less';

.session-extension-sheet {
  margin: @ppui-spacing-xl-3 0;

  @media @isDesktopOrTablet {
    margin-top: @ppui-spacing-xl-5;
    min-width: @ppui-grid-media-query-md;
  }

  .stay-logged-in-button {
    margin-top: @ppui-spacing-xl-3;
  }
}

.session-extension-index {
  z-index: 99999;
}