@import (reference) "../../../../src/static/styles.less";

.renewal-tile {
  .renewal-well {
    .vx_panel-well;
    .reversed;
    padding: 12px 24px;
    border-radius: 2px;
  }

  .renewal-header {
    .vx_text-body-md_medium;
    .reversed;
    margin: 20px 0;
  }
}

