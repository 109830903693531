@import "~@swift-paypal/pp-vx/dist/core/utilities/variables.less";

.forgiveness-application-sidebar {
  .header {
    margin-bottom: 0;
  }

  .tile-contents {
    margin-top: 0.5rem;

    .label-caption {
      color: @ppui-color-grey-600;
      font-size:  0.8125rem;
    }
  }
}
