@import (reference) "../../../../src/static/styles.less";


.have-questions-container {
  padding: 0;

  @media (max-width: @phonePortraitMax) {
    padding: 0 0 5rem;
  }

  .have-questions-title {
    color: @ppui-color-grey-600;
    margin-block-end: 0.5rem;
  }

  .call-us-text, .call-us-hours, .email-us__text {
    margin: 0;
  }

  .email-us {
    margin-bottom: 0.75rem;
    &__email-link {
      padding: 0;
    }
  }
}
