@import (reference) "../../src/static/styles.less";
.security-question, .forgot-password {
    .lb-logo{
        width: 30rem;
        @media @isMobile{
            width: 80%;
            margin-left: auto;
        }
    } 

    .fp-heading{
        font-weight: @ppui-font-weight-light;
        margin: 0 1rem 1rem 1rem;
    }

    .fp-caption{
        font-weight: @ppui-font-weight-light;
        color: @tertiaryLightGray;
        padding: 1rem 0;
        width: 22rem;
        margin-left: calc(50% - 11rem);
        @media @isMobile{
            width: 80%;
            margin-left: calc(10%);
        }
    }

    .fp-input{
        width: 30rem;
        margin-left: calc(50% - 15rem);
        @media @isMobile{
            width: 80%;
            margin-left: calc(10%);
        }
    }

    .next-button{
        min-width: 10rem;
    }

    .vx_anchor{
        cursor: pointer;
        border: none; 
        background-color: transparent; 
        outline: none;
        margin-bottom: 1rem;
        :focus:hover {
            text-emphasis: none;
        }
    }

    .question{
        margin-top: 2rem;
    }

    .need-help{
        margin-top: 2rem;
        .text {
            line-height: @ppui-line-height-lg;
        }
        .divider {
            position:relative;
            text-align:center;
        }    
        .divider span {
            display:inline-block;
            padding:0 10px;
            background:#fff;
        }
        .divider:before {
            content:"";
            display:block;
            position:absolute;
            z-index:-1;
            left:0;
            right:0;
            top:50%;
            height:1px;
            background:#ccc;
        }
    }
}