@import (reference) "../../../../src/static/styles.less";

.login-form-revamp {
  .form-control {
    margin: 0;
  }
  .login-button {
    margin: 1.5rem 0 0.625rem 0;
  }
  .alert{
    margin-top: 0.625rem;
  }
  .vx_form-control-message {
    font-size: @ppui-font-size-sm;
    margin-bottom: 0;
  }
}
