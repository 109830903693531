@import (reference) '../../../src/static/styles.less';

.activity {
  margin-left: 0;
  margin-right:0;

  .guaranty-claim-messaging {
    background-color: @ppui-color-grey-100;
    border-radius: @ppui-border-radius-sm;
    margin: @ppui-spacing-xl-2 0;
    padding: @ppui-spacing-xl @ppui-spacing-xl;
  }

  .desktop {
    @media @isTabletOrMobile {
      display: none;
    }
  }
  
  .activity-hr {
    .vx_hr;
    margin: 0 12px 1.2rem 12px;

    @media (max-width: @maxPhone) {
      margin: 0 0 0 0;
    }
  }

  .main-text {
    .vx_text-body-lg;
    .bold-body-text;
  }

  .negative-left-padding {
    margin-left: -@global-padding;
    margin-right: -@global-padding;
  }

  .sub-text {
    .vx_text-body-lg;
  }

  .transaction-separator {
    margin: 5rem 0;
  }

  .subsection {
    @media (min-width: 401px) {
      margin-left: 27px;
    }
  }

  .activity-pull-right {
    .pull-right;
    @media (max-width: 400px) {
      float: none;
      padding-left: 1rem;
    }
  }

  .full-width-phone {
    @media (max-width: @maxPhone) {
      .negative-left-padding;
    }
  }

  .phone-wrap {
    @media (max-width: @maxPhone) {
      margin-left: @global-padding;
    }
  }
}


