@import (reference) "../../../../src/styles/variables.less";
@media (max-width: @maxPhone) {
  li.dropdown.activity, .vx_globalNav-nav {
    display: block !important;
  }
}
.activity {
  ul.dropdown-content {
    .no-activity {
      padding: 10px 20px 10px 20px;
      min-width: 220px;
      text-align: center !important;
      cursor: auto;
    }
  }
}
