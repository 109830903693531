@import (reference) "../../../../styles/variables.less";

.for-you-card {
  background-color: @ppui-color-grey-100;
  border-radius: @ppui-border-radius-sm;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  margin: @ppui-spacing-xl-2 0 0 0;
  padding: @ppui-spacing-xl;
  width: 85%;

  .call-to-action {
    margin: @ppui-spacing-xl @ppui-spacing-xl @ppui-spacing-xs 0;

    @media @isTabletOrMobile {
      display: flex;
      justify-content: center;
    }
  }

  @media @isTabletOrMobile {
    width: 100%;
    grid-template-columns: auto;
  }
}